import React from "react";
import { Link } from "react-router-dom";
import tyImage from "../../images/thankyou.jpg";

function Content() {
  return (
    <>
      <div>
        <section className="contact-header">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-xl-8 wow fadeInRightBig mx-auto"
                data-wow-delay="0.2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInRightBig",
                }}
              >
                <div className="inner-title-small">
                  <h3 className="p-2"></h3>
                  <h3>Thank you</h3>
                  <div>
                    <p>
                      If you need to get in touch, you can contact our friendly
                      helpful team via phone, email or live chat
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb-140 payment-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="tnk-box">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                      <div className="tnk-box-img">
                        <img src={tyImage} alt />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="tnk-content-box">
                        <div className="tnkyou-content">
                          <h3>Thank You !</h3>
                          <h4>
                            Your Payment is Successfull will get back to you{" "}
                          </h4>
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit.{" "}
                          </p>
                        </div>
                        <div>
                          <Link to="/" className="pay-btn mb-3">
                            Keep Shopping
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Content;
