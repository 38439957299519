import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Content from "../Components/ThankYou/Content";

function ThankYou() {
  return (
    <>
      <Header />
      <div className="body-box-margin">
        <Content />
        <Footer />
      </div>
    </>
  );
}

export default ThankYou;
